import React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';

import { SimpleSelect } from "components/FormSelect";
import CustomButton from 'components/CustomButton';
import { useLoading, useSnackbar } from 'contexts';
import { getUsers, exportTemplates, createDuplicateMockTemplate } from "services";
import { ROLES } from '../../config';
import { getBatches } from 'services/batch';
import { SimpleTextField } from 'components/FormTextField';

function DuplicateMockTemplateDialog({
    open,
    onClose,
    onSuccess,
    fromTemplate,
    toTemplateName
}) {
    const loading = useLoading();
    const snackbar = useSnackbar();

    const [clients, setClients] = React.useState([]);
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [templateName, setTemplateName] = React.useState("");

    React.useEffect(() => {

        if (open) {
            (async () => {
                try {
                    loading.show();
                    setSelectedClient(null);

                    const { users } = await getUsers({ roleNames: ROLES.MOCK_CLIENT });

                    setClients(users);
                    setTemplateName(toTemplateName);
                } catch (error) {
                    snackbar.showSnackbar(error.message, "error", true);
                } finally {
                    loading.hide();
                }
            })();
        }
    }, [open]);


    const handleSaveClick = async () => {
        try {
            loading.show();

            if (!(fromTemplate && selectedClient && toTemplateName)) {
                snackbar.showSnackbar("Invalid data", "error", true);
            }

            await createDuplicateMockTemplate(fromTemplate, selectedClient, toTemplateName);

            snackbar.showSnackbar("Template duplicated successfully", "success", true);
            onClose();
        } catch (error) {
            snackbar.showSnackbar(error.message, "error", true);
        } finally {
            loading.hide();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                Create mock template duplicate
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 380, maxWidth: 500 }}>
                <SimpleSelect
                    options={clients}
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    label="Select a mock client"
                />

                <SimpleTextField
                    label="Template Name"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                />

                <Box display="flex">
                    <CustomButton
                        variant="contained"
                        onClick={handleSaveClick}
                        disabled={!selectedClient}
                    >
                        Save
                    </CustomButton>
                    <CustomButton
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </CustomButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default DuplicateMockTemplateDialog;