import React, { useState , useEffect } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import { Sections } from './constants';
import { IELTSPart } from './Part';
import { createIELTSMockSection, createTOEFLMockSection, deleteMockSectionById, generateTemplateId } from 'services';
import { useLoading, useSnackbar } from 'contexts';
import ClientSelect from 'components/ClientSelect';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Section-wise timing in seconds
const SectionTimeMap = {
  ielts: {
    short: {
      speaking: 5 * 60, writing: 5 * 60, listening: 11 * 60, reading: 9 * 60,
    },
    full: {
      speaking: 15 * 60, writing: 30 * 60, listening: 30 * 60, reading: 60 * 60,
    },
  },
  toefl: {
    short: {
      speaking: 5 * 60, writing: 8 * 60, listening: 7 * 60, reading: 10 * 60,
    },
    full: {
      speaking: 20 * 60, writing: 60 * 60, listening: 45 * 60, reading: 45 * 60,
    },
  }
};

const sectionDurations = {
  listening: 40,
  reading: 60,
  writing: 60,
  speaking: 20,
};

function MockSectionCreator(props) {
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [expanded, setExpanded] = React.useState(0);
  const [templateId , setTemplateId] = useState();
  const [user , setUser] = useState();
  const [templateName , setTemplateName] = useState('');
  const [mockType, setMockType] = React.useState('ielts');
  const [module , setModule] = useState();
  const [time , setTime] = ([30])
  const [short, setShort] = React.useState('');
  const [section, setSection] = React.useState('');
  const [sectionDuration, setSectionDuration] = React.useState('');
  const [parts, setParts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const [editSectionIndex, setEditSectionIndex] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  useEffect(() => {
    if (sectionDurations[section]) {
      setSectionDuration(sectionDurations[section]);
    }
  }, [section]);

  const handleTemplateId = async () => {
    try {
        const response = await generateTemplateId();
        setTemplateId(response);
        snackbar.showSnackbar("Template Id generated successfully");
    } catch (error) {
        console.error("Error generating template ID:", error);
    }
};

  const handleSection = () => {
    setSection('');
    setSectionDuration(null)
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };
  
    const handleSaveSection = () => {
      if (section && sectionDuration) {
        const newSection = {
          section,
          sectionDuration,
          parts: section === "listening" ? [{ description: "", audio: "", questionsRange: [] }] : [{ description: "", questionsRange: [] }],
          questions: 0
        };
        setSectionsArray([...sectionsArray, newSection]);
        handleClose();
      } else {
        snackbar.showSnackbar("Please fill all details", "error");
      }
    };
  
    const handleDeleteSection = async(sectionId) => {
      try {
        await deleteMockSectionById(sectionId)
        setSectionsArray(sectionsArray.filter((section) => section.sectionId !== sectionId));
        snackbar.showSnackbar("Section deleted successfully");
    } catch (error) {
        console.error("Error generating template ID:", error);
    }
      
    };
      
    const handleEditSection = (index) => {
      setEditSectionIndex(index);
      setEditDialogOpen(true);
    };
  
    const handleCloseEditDialog = () => {
      setEditDialogOpen(false);
      setEditSectionIndex(null);
    };
  
    const addNewPart = () => {
      if (editSectionIndex !== null) {
        setSectionsArray(prevSections => {
          const updatedSections = [...prevSections];
          const sectionName = updatedSections[editSectionIndex].section;
          const newPart = sectionName === "listening" ? { description: "", audio: "", questionsRange: [] } : { description: "", questionsRange: [] };
          updatedSections[editSectionIndex] = {
            ...updatedSections[editSectionIndex],
            parts: [...updatedSections[editSectionIndex].parts, newPart]
          };
          return updatedSections;
        });
      }
    };
  
  const deletePart = (partIndex) => {
    if (editSectionIndex !== null) {
      setSectionsArray(prevSections => {
        const updatedSections = [...prevSections];
        updatedSections[editSectionIndex] = {
          ...updatedSections[editSectionIndex],
          parts: updatedSections[editSectionIndex].parts.filter((_, i) => i !== partIndex)
        };
        return updatedSections;
      });
    }
  };
  

  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const updatePart = (partIndex, updatedPart) => {
    if (editSectionIndex !== null) {
      setSectionsArray(prevSections => {
        const updatedSections = [...prevSections];
        updatedSections[editSectionIndex].parts[partIndex] = updatedPart;
        return updatedSections;
      });
    }
  };

  const saveSection = async () => {
    try {
      loading.show();

      const name = section
        ? section.charAt(0).toUpperCase() + section.slice(1)
        : "";
      const duration = parseInt(sectionDuration, 10) * 60;   

      if (mockType === "ielts") {
        const response = await createIELTSMockSection({
          name,
          mockType, user , templateId , templateName, module, duration    
        });
      
      const newSection = {
        sectionId : response._id,
        section,
        sectionDuration,
        parts: section === "listening" ? [{ description: "", audio: "", questionsRange: [] }] : [{ description: "", questionsRange: [] }],
        questions: 0
      };
      setSectionsArray([...sectionsArray, newSection]);
      handleClose(); 
        
      } else {
        await createTOEFLMockSection({
          mockType, duration: duration * 60, parts, short,
          name: section.charAt(0).toUpperCase().concat(section.slice(1)),
        });
      }
      snackbar.showSnackbar("Section created successfully");
    } catch (error) {
      console.error(error);
      snackbar.showSnackbar(
        error.message || "Uh Oh! Something went wrong", "error"
      );
    } finally {
      loading.hide();
    }
  };

  // React.useEffect(() => {
  //   if (mockType && section && short !== '') {
  //     setSectionDuration(SectionTimeMap[mockType][short ? 'short' : 'full'][section] / 60);
  //   }
  // }, [mockType, section, short]);
  
  return (
    <>
      <ContentHeader title={"Mock Template"}>
      </ContentHeader>
      <Box
        py={2}
        display="flex"
        alignItems="center"
        gap={2}
      >
        <FormControl size="small" required>
          <CustomButton 
            variant='contained'
            onClick={handleTemplateId}
            disabled={templateId}
          >
            {templateId ? templateId : 'Generate Template ID'}
          </CustomButton>
        </FormControl>
        <FormControl size='small'>
          <ClientSelect
              client='MOCK_CLIENT'
              onChange={(client) => setUser(client._id)}
              sx={{width : '200px'}}
          />
        </FormControl>
        <TextField
          required
          size="small"
          label="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)
          }
          style={{ width: 200, margin: 0 }}
        />
        <FormControl size="small" required disabled={parts.length > 0}>
          <InputLabel id="mock-type-select-label">
            Mock Type
          </InputLabel>
          <Select
            label="Mock Type"
            labelId='mock-type-select-label'
            value={mockType}
            onChange={(e) => setMockType(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            <MenuItem value="toefl">TOEFL</MenuItem>
            <MenuItem value="ielts">IELTS</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" required disabled={parts.length > 0}>
          <InputLabel id="section-select-label">
            Module
          </InputLabel>
          <Select
            label="Module"
            value={module}
            onChange={(e) => setModule(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            <MenuItem value="academic">
              <em>Academic</em>
            </MenuItem>
           <MenuItem value="general_practice">
           <em>General Practice</em>
           </MenuItem>
          </Select>
        </FormControl>
        <CustomButton 
          disabled={!mockType || !templateId || !module || !templateName || !user} 
          onClick={handleSection} 
          variant='contained'
        >
          Add Section
        </CustomButton>
       
      </Box>

      <Box display="flex" flexWrap="wrap" gap={2} mt={5}>
        {sectionsArray.map((sec, index) => (
          <Box key={index} p={2} border={1} borderRadius={2} width={400}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant='h6'>{sec.section}</Typography>
              <IconButton onClick={() => handleDeleteSection(sec.sectionId)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box mt={2}>
              <Typography variant='body1'><strong>Parts:</strong> {sec.parts.length}</Typography>
              <Typography variant='body1'><strong>Questions:</strong> {sec.questions}</Typography>
              <Typography variant='body1'><strong>Duration:</strong> {sec.sectionDuration} min</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              {/* <Button variant="contained"  onClick={() => handleEditSection(index)}>Add Questions</Button> */}
              {/* <Button 
                variant="contained" 
                color="primary"
                onClick={saveSection}
              >
                Generate Section
              </Button> */}
            </Box>
          </Box>
        ))}
      </Box>


      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Add Section
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
          <InputLabel id="section-select-label">
            Section Name
          </InputLabel>
          <Select
            label="Section Name"
            labelId="section-select-label"
            value={section}
            onChange={(e) => setSection(e.target.value)}
            style={{ minWidth: 200, flexShrink: 0, padding: 0 }}
          >
            {Sections.map((section, i) => (
              <MenuItem key={i} value={section.id}>
                {section.label}
              </MenuItem>
            ))}
          </Select>

          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              required
              inputProps={{ min: 0, max: 7200 }}
              size="small"
              label="Total Duration(min)"
              type={"number"}
              variant="outlined" 
              InputLabelProps={{ shrink: true }}
              value={sectionDuration}
              onChange={(e) => setSectionDuration(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={saveSection} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={editDialogOpen} 
        onClose={handleCloseEditDialog}  
        sx={{ '& .MuiDialog-paper': { width: '90vw', height: '90vh', maxWidth: '100%' } }}
      >
        <DialogTitle>
          Edit {sectionsArray[editSectionIndex]?.section} Section
          <Button onClick={addNewPart} variant="contained" sx={{ position: 'absolute', right: 16, top: 24 }}>
            Add Parts
          </Button>
        </DialogTitle>
        <DialogContent>
          {sectionsArray[editSectionIndex]?.parts.map((part, partIndex) => (
            <IELTSPart
              key={partIndex}
              index={partIndex}
              onDelete={deletePart}
              expanded={expanded}
              onExpandChange={handleExpandChange(partIndex)}
              part={part}
              section={sectionsArray[editSectionIndex]?.section}
              mockType={mockType}
              updatePart={updatePart}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MockSectionCreator;