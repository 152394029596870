export const Sections = [
  { id: "reading", name: "Reading" },
  { id: "writing", name: "Writing" },
  { id: "listening", name: "Listening" },
  { id: "speaking", name: "Speaking" },
];

export const groupTemplateMapping = {
  mcq: (index) => ({
    type: "mcq",
    questionsRange: [],
    description: "",
    passage: "",
    part: index,
  }),
  scq: (index) => ({
    type: "scq",
    questionsRange: [],
    description: "",
    passage: "",
    part: index,
  }),
  fib: (index) => ({
    type: "fib",
    questionsRange: [],
    description: "",
    image: "",
    part: index,
  }),
  spoken: (index) => ({
    type: "spoken",
    questionsRange: [],
    description: "",
    image: "", // for cue card
    part: index,
  }),
  written: (index) => ({
    type: "written",
    questionsRange: [],
    description: "",
    image: "",
    part: index,
  }),
  img_dnd: (index) => ({
    type: "img_dnd",
    questionsRange: [],
    description: "",
    options: [],
    image: "",
    part: index,
  }),
  para_dnd: (index) => ({
    type: "para_dnd",
    questionsRange: [],
    description: "",
    options: [],
    part: index,
    content: []
  }),
  passage_dnd: (index) => ({
    type: "passage_dnd",
    questionsRange: [],
    description: "",
    options: [],
    part: index,
    passage: "",
    content: []
  }),
};

export const MockSectionQuestionTypeMapping = {
  "ielts": {
    "reading": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "fib", label: "Fill in the blanks" },
      { id: "img_dnd", label: "Image Drag And Drop" },
      { id: "para_dnd", label: "Paragraph Drag And Drop" },
      { id: "passage_dnd", label: "Passage Drag And Drop" }
    ],
    "listening": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "fib", label: "Fill in the Blanks" },
      { id: "img_dnd", label: "Image Drag And Drop" },
      { id: "para_dnd", label: "Paragraph Drag And Drop" },
      { id: "passage_dnd", label: "Passage Drag And Drop" }
      // { id: "mark_the_para", label: "Mark the paragraph" },
    ],
    "writing": [{ id: "written", label: "Written" },],
    "speaking": [{ id: "spoken", label: "Spoken" }],
  },
  "toefl": {
    "reading": [
      { id: "scq", label: "SCQ" },
      { id: "mcq", label: "MCQ" },
      { id: "drag_and_drop", label: "Drag and Drop" }
    ],
    "listening": [{ id: "scq", label: "SCQ" }, { id: "mcq", label: "MCQ" }],
    "speaking": [{ id: "spoken", label: "Spoken" }],
    "writing": [{ id: "written", label: "Written" },],
  },
};

export const TOEFLWritingSubSections = [
  { id: "BRIEF", label: "Brief" },
  { id: "AUDIO", label: "Audio Passage" },
  { id: "PASSAGE", label: "Passage" },
  { id: "QUESTION", label: "Question" }
];
