import Client from "./_client";

export const MockSectionTypeOptions = [
  { value: 'ielts', label: 'IELTS' },
  { value: 'toefl', label: 'TOEFL' }
];

export const MockModuleOptions = [
  { value: 'academic', label: 'Academic' },
  { value: 'general_practice', label: 'General Practice' }
]

export const getMockSections = async (filters = {}) => {
  let url = "/mockSection?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch Mock sections!");

  return result.data || [];
};

export const getMockTemplates = async (filters = {}) => {
  let url = "/mockSection/templates?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch Mock sections!");

  return result.data || [];
};

export const getMockSectionById = async (id) => {
  const result = await new Client({
    path: `/mockSection/${id}`,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to fetch Mock sections!");

  return result.data || [];
};

export const deleteMockSectionById = async (mockSectionId) => {

  let url = `/mockSection/${mockSectionId}`;

  let result = await new Client({
    path: url
  }).delete();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to delete mock section. Please try again."
    );

  return result.data;
};

export const deleteMockTemplateById = async (mockTemplateId) => {

  let url = `/mockSection/template/${mockTemplateId}`;

  let result = await new Client({
    path: url
  }).delete();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to delete mock template. Please try again."
    );

  return result.data;
};

export const generateTemplateId = async () => {

  let url = `/utils/generate/document-id`;

  let result = await new Client({
    path: url
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to generate template id. Please try again."
    );

  return result.data;
}

export const saveMockSection = async (mockSectionId, mockSection) => {
  let result;

  if (mockSectionId) {
    result = await new Client({
      path: `/mockSection/${mockSectionId}`,
      payload: mockSection,
    }).put();
  } else {
    result = await new Client({
      path: "/mockSection",
      payload: mockSection,
    }).post();
  }

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message ||
      "Uh Oh! Unable to save mock section. Please try again."
    );
  }

  return result.data;
};

const IETLSTiming = {
  reading: [60],
  writing: [60],
  listening: [30, 60],
  speaking: [60, 60, 60],
};

export const createIELTSMockSection = async ({
  parts: rawParts, ...mockSectionDetails
}) => {

  const questions = [];
  const groups = [];
  const parts = [];
  const answers = [];
  const time = [30]

  // for (let i = 0; i < rawParts.length; i++) {
  //   const part = rawParts[i];

  //   const partStartRange = questions.length + 1;

  //   for (let j = 0; j < part.groups.length; j++) {
  //     const group = part.groups[j];

  //     const groupStartRange = questions.length + 1;

  //     for (let k = 0; k < group.questions.length; k++) {
  //       const question = group.questions[k];

  //       let content = question.content;

  //       if (group.type === 'fib') {
  //         content = content.map(c => c.type === 'FIB' ? "__FIB__" : c.content);
  //       }

  //       questions.push({
  //         type: group.type,
  //         part: i,
  //         group: groups.length,
  //         options: question.options,
  //         expectedWords: question.expectedWords || null,
  //         time: question.time || null,
  //         audio: question.audio || null,
  //         content,
  //       });

  //       answers.push(question.answer);
  //     }

  //     groups.push({
  //       instruction: group.instrution || '',
  //       type: group.type,
  //       options: group.options || [],
  //       image: group.image || '',
  //       questionsRange: [
  //         groupStartRange,
  //         groupStartRange + (questions.length - groupStartRange)
  //       ],
  //     });
  //   }

  //   parts.push({
  //     description: part.description,
  //     passage: part.passage,
  //     audio: part.audio || '',
  //     questionsRange: [
  //       partStartRange,
  //       partStartRange + (questions.length - partStartRange)
  //     ],
  //   });
  // }

  return await saveMockSection(null, {
    ...mockSectionDetails, time, parts, questions, groups, answers,
  });
};

export const createTOEFLMockSection = async ({
  parts: rawParts, ...mockSectionDetails
}) => {

  const questions = [];
  const passages = [];
  const answers = [];

  if (!rawParts || rawParts?.length <= 0) {
    throw new Error("Add at least one part to add the section");
  }

  for (let i = 0; i < rawParts.length; i++) {
    const part = rawParts[i];

    const name = mockSectionDetails.name;

    if (["Writing", "Speaking"].includes(name)) {
      const question = {
        type: name === "Writing" ? "written" : "spoken", subSections: part.subSections
      };

      if (part.audio) {
        part.audio.imageUrl = "https://assets.languify.in/images/audio_passage_image.png";
        question.audioPassage = part.audio;
      }
      if (part.brief) question.brief = part.brief;
      if (part.passage) question.passage = part.passage;
      if (part.question) question.question = part.question;

      questions.push(question);
      answers.push('');
    } else {
      for (let k = 0; k < part.questions.length; k++) {
        const question = part.questions[k];

        let content = question.content;

        if (question.type === 'fib') {
          content = content.map(c => c.type === 'FIB' ? "__FIB__" : c.content);
        }

        questions.push({
          passage: i,
          type: question.type,
          options: question.options,
          expectedWords: question.expectedWords || null,
          time: question.time || null,
          audio: question.audio || null,
          content,
        });

        answers.push(question.answer);
      }

      passages.push({
        description: part.description || '',
        content: part.passage || '',
        audioUrl: part.audio || null,
        imageUrl: part.image || (name === "Listening" ? "https://assets.languify.in/images/audio_passage_image.png" : null),
        timeForQuestions: part.timeForQuestions || 0,
        type: part.type || '',
      });
    }
  }

  return await saveMockSection(null, {
    passages, questions, answers, ...mockSectionDetails, isActive: true,
    time: [mockSectionDetails.duration]
  });
};

export const createDuplicateMockTemplate = async (fromTemplate, toUser, toTemplateName) => {
  let url = `/mockSection/template/${fromTemplate}/duplicate`;

  const result = await new Client({
    path: url,
    method: 'POST',
    payload: { toUser, toTemplateName }
  }).post();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to duplicate the mock template!");

  return result.data || [];
};