import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FormLabel from '@mui/material/FormLabel';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';

import { Link } from 'react-router-dom';
import { MockClientSelect } from 'components/ClientSelect';
import { SimpleTextField } from 'components/FormTextField';
import { useSnackbar } from "contexts";
import { generateMockMagicLink, generateMockReportLink, getMockTemplates } from 'services';
import { copyToClipboard } from "utils";
import { useEffect } from 'react';
import { Divider, MenuItem, Select } from '@mui/material';
import { Sections } from './MockSectionEditor/constants';
import { SimpleSelect } from 'components/FormSelect';

function MockMagicLink(props) {
    const snackbar = useSnackbar();

    const [client, setClient] = React.useState(null);
    const [templates, setTemplates] = React.useState([])
    const [attemptId, setAttemptId] = React.useState("");
    const [sections, setSections] = React.useState([])
    const [error, setError] = React.useState("");
    const [inputs, setInputs] = React.useState({});
    const [link, setLink] = React.useState("");
    const [reportLink, setReportLink] = React.useState("");
    const [localLink, setLocalLink] = React.useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    useEffect(() => {
        const queryUrl = link?.split('//')[1]?.split('/')[1];
        setLocalLink(`http://localhost:3000/${queryUrl}`);
    }, [link]);

    const getTemplates = async (clientId) => {
        try {
            const templates = await getMockTemplates({ user: clientId });

            setTemplates(templates.map((t) => ({ "_id": t.templateId, "name": t.templateName })));
        } catch (err) {
            snackbar.showSnackbar('Error fetching templates', err);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setError("")
        if (!client) {
            setError("Select client")
            return false;
        }

        generateMockMagicLink({
            user: {
                name: inputs.name,
                email: inputs.email
            },
            templateId: inputs.template,
            sections: sections,
            redirectUrl: inputs.redirectUrl,
            clientId: client._id
        })
            .then((data) => {
                setLink(data.link);
                setAttemptId(data.attemptId);
                snackbar.showSnackbar('Link generated successfully')
            })
            .catch((err) => snackbar.showSnackbar(err.message, "error"));
    };

    const fetchReportLink = (e) => {
        e.preventDefault();

        if (!client) {
            snackbar.showSnackbar('Select client for fetch report link', "error")
            return false;
        }

        generateMockReportLink({
            attemptId
        })
            .then((data) => {
                setReportLink(data.reportLink);
                snackbar.showSnackbar('Link generated successfully')
            })
            .catch((err) => snackbar.showSnackbar(err.message, "error"));
    }


    return (
        <>
            <ContentHeader title="Mock Api integration" />
            <br />
            <br />
            <Box width="50%" m="auto">
                <form onSubmit={handleSubmit}>
                    <Typography>Generate mock magic link -</Typography>
                    <MockClientSelect
                        error={error}
                        defaultValue={client?._id}
                        onChange={(client) => {
                            getTemplates(client._id);
                            setClient(client)
                        }}
                    />

                    <SimpleTextField
                        placeholder="john@gmail.com"
                        name="email"
                        label="User Email"
                        type="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <SimpleTextField
                        placeholder="Name of the user"
                        name="name"
                        label="User name"
                        type="text"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />

                    {
                        (templates.length) ?
                            <SimpleSelect
                                name="template"
                                label="Template"
                                required
                                value={inputs.template}
                                options={templates}
                                onChange={handleChange}
                            /> :
                            <p style={{ color: 'red', }}>
                                No templates found. Select a different client or create a template to generate a link
                            </p>

                    }

                    <FormControl style={{ width: '448px' }}>
                        <FormLabel sx={{ fontSize: '14px' }} id="section-select-label">
                            Section Name (multiple select) (optional)
                        </FormLabel>
                        <Select
                            labelId="section-select-label"
                            multiple
                            value={sections}  // Now it should be an array
                            onChange={(e) => setSections(e.target.value)} // e.target.value will be an array
                            style={{ height: '34px', flexShrink: 0, padding: 0, fontSize: '14px' }}
                            placeholder='Section Name'
                            renderValue={(selected) => selected.map(id =>
                                Sections.find(section => section.id === id)?.name).join(', ') || 'Select Sections'}
                        >
                            {Sections.map((section, i) => (
                                <MenuItem key={i} value={section.id}>
                                    {section.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <br />
                    <SimpleTextField
                        placeholder="redirectUrl"
                        name="redirectUrl"
                        label="Redirect URL (optional)"
                        type="text"
                        value={inputs.redirectUrl}
                        onChange={handleChange}
                    />
                    <br />
                    <CustomButton variant="contained" type="submit" name="sep">
                        Generate Link
                    </CustomButton>
                </form>
                <br />

            </Box>
            {link && (
                <>
                    <Box display="flex" alignItems="center" >
                        <SimpleTextField
                            readOnly
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={link}
                            spellCheck="false"
                            disabled
                        >
                        </SimpleTextField>
                        &nbsp;&nbsp;
                        <ContentCopyOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyToClipboard(link);
                                snackbar.showSnackbar('copied!', 'info', true);
                            }}
                        />
                        <Link to={`/integrations/iframe?url=${link}`} style={{ textDecoration: "none", }} >
                            <Button
                                style={{ width: 160 }}
                                variant="contained"
                            >
                                View in IFrame
                            </Button>
                        </Link>
                    </Box>

                </>
            )}

            <br />  <Divider /> <br />


            <Box width="50%" m="auto">
                <form onSubmit={fetchReportLink}>
                    <Typography>Fetch report link -</Typography>

                    <SimpleTextField
                        placeholder="6428486e18089f25ee1fb806"
                        name="attemptId"
                        label="Attempt id"
                        type="text"
                        value={attemptId || ""}
                        onChange={(e) => setAttemptId(e.target.value)}
                        required
                    />
                    <br />

                    <CustomButton variant="contained" type="submit" name="sep">
                        Get Report link
                    </CustomButton>
                    {reportLink && (
                        <>
                            <Box display="flex" alignItems="center" >
                                <SimpleTextField
                                    readOnly
                                    size='small'
                                    variant="outlined"
                                    fullWidth
                                    value={reportLink}
                                    spellCheck="false"
                                    disabled
                                >
                                </SimpleTextField>
                                &nbsp;&nbsp;
                                <ContentCopyOutlinedIcon
                                    fontSize="medium"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        copyToClipboard(reportLink);
                                        snackbar.showSnackbar('copied!', 'info', true);
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </form>
                <br />

            </Box>
        </>
    );
}

export default MockMagicLink;