import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useList } from "hooks/useList";
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import GridToolbar from 'components/GridToolbar';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ListPagination from 'components/ListPagination';
import {
    MockSectionTypeOptions,
    getMockSectionById,
    saveMockSection,
    getMockTemplates,
    MockModuleOptions,
    deleteMockTemplateById
} from "services/mockSections";
import { getListActions, checkCreatePermission } from "utils/listActions";
import EntityViewDialog from 'components/dialogs/EntityViewDialog';
import { useLoading, useSnackbar } from 'contexts';
import FilterList from './FilterList';
import MockSelect from 'components/MockSelect';
import { TextField } from '@mui/material';
import DuplicateMockTemplateDialog from 'components/dialogs/DuplicateMockTemplateDialog';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    }
}));

const BaseFilters = {
    include: ["user"],
    includeTotal: true,
};

const FilterFieldMap = {
    usedBy: "metadata.usedBy",
    numberOfQuestions: "metadata.numberOfQuestions"
};

export default function MockSections() {
    const classes = useStyles();

    const { id } = useParams();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const loader = useLoading();

    const [activeItemId, setActiveItemId] = React.useState(null);
    const [selectedSections, setSelectedSections] = React.useState([]);
    const [selectedSection, setSelectedSection] = React.useState(null);
    const [openDuplicateDialog, setOpenDuplicateDialog] = React.useState(false);
    const [selectedTemplates, setSelectedTemplates] = React.useState([]);

    const {
        loading,
        items,
        total,
        editItem,
        deleteItem,
        filters,
        setFilters,
        setSortModel,
        setPageNo,
        setPageSize,
    } = useList({
        getItems: getMockTemplates,
        initialFilters: BaseFilters,
        initialPageNo: 0,
        initialPageSize: 100,
        itemName: "mockSections",
        deleteItemById: deleteMockTemplateById,
    });

    const handleFilterChange = ({ items }) => {
        const { columnField, value } = items[0];
        if (value)
            setFilters({
                ...BaseFilters,
                ...filters,
                [FilterFieldMap[columnField] || columnField]: value
            });
        else
            setFilters(BaseFilters, ...filters);
    };

    const handleSortModelChange = (newModel) => {

        if (newModel[0]) {
            const { field, sort } = newModel[0];
            setSortModel({ orderBy: field, order: sort });
        }
    };

    const handleSelectionChange = (value, type) => {
        if (!value) return;
        handleFilterChange({
            items: [{ columnField: type, value: value }]
        })

    }

    const handleConfirmationSuccess = () => {
        deleteItem(activeItemId);
        setActiveItemId(null);
    };

    const handleSelectedRowChange = (selectedIds) => {
        const _filtedTemplates = items.filter(({ templateId }) => selectedIds.includes(templateId));
        setSelectedTemplates(_filtedTemplates);
        setSelectedSections(selectedIds);
    }

    const columns = React.useMemo(() => {
        return [
            {
                field: 'user',
                headerName: 'Client',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
                valueGetter: (params) => params?.row?.user?.name
            },
            {
                field: 'mockType',
                headerName: 'Test Type',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                valueOptions: MockSectionTypeOptions,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'templateName',
                headerName: 'Template Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
            },
            {
                field: 'duration',
                headerName: 'Duration',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
                valueGetter: (params) => `${Math.round(params?.row?.duration / 60)} min`
            },
            {
                field: 'module',
                headerName: 'Module',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterable: true,
                valueOptions: MockModuleOptions,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'equals',
                ),
            },
            {
                field: 'sections',
                headerName: 'Sections Name',
                flex: 1,
                headerClassName: classes.tableHeader,
                filterOperators: getGridStringOperators().filter(
                    (operator) => operator.value === 'contains',
                ),
                valueGetter: (params) => params.row.sections?.map(s => s.name).join(', ') || ''
            },
            {
                field: 'actions',
                headerName: 'Actions',
                type: "actions",
                width: 120,
                headerClassName: classes.tableHeader,
                getActions: (params) => {
                    const actions = getListActions(
                        params.id, null, setActiveItemId, "MockSection"
                    );

                    return actions;
                }
            },
        ];
    }, [classes.tableHeader, editItem]);

    const handleCloseEdit = async (e, editedObject) => {
        try {
            loader.show();
            if (editedObject) {
                await saveMockSection(editedObject._id, editedObject);

                snackbar.showSnackbar("Updated mock section!");
            }
            setSelectedSection(false);
            navigate(-1);
        } catch (error) {
            console.error(error);
            snackbar.showSnackbar("Unable to update mock section!!", "error");
        } finally {
            loader.hide();
        }
    };

    React.useEffect(() => {
        setSelectedSection(id);
    }, [id]);

    return (
        <Box>
            <ContentHeader
                title={"IELTS & TOEFL Templates"}
                BaseFilters={BaseFilters}
                filters={filters}
                setFilters={setFilters}
                clearFilter
            >
                <CustomButton
                    variant="contained"
                    onClick={e => setOpenDuplicateDialog(true)}
                    disabled={selectedTemplates.length !== 1}
                >
                    Duplicate
                </CustomButton>

                <FilterList width={"10rem"}>
                    <MockSelect onChange={handleSelectionChange} type='mockType' label={"Test Type"} />
                    <MockSelect onChange={handleSelectionChange} type='name' label={"Section Name"} />
                    <TextField
                        label="Template Name"
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleSelectionChange(e.target.value, 'templateName')}
                    />
                    <TextField
                        label="Template Id"
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleSelectionChange(e.target.value, 'templateId')}
                    />
                    <TextField
                        label="Duration"
                        variant="outlined"
                        size="small"
                        type="number"
                        onChange={(e) => handleSelectionChange((e.target.value) * 60, 'duration')}
                    />
                    <MockSelect onChange={handleSelectionChange} type='module' label={"Module"} />
                </FilterList>
                {checkCreatePermission("MockSection") &&
                    <CustomButton
                        variant="contained"
                        onClick={editItem}
                    >
                        Create
                    </CustomButton>
                }
            </ContentHeader>
            <div style={{ height: '80vh', width: '100%', padding: 16 }}>
                <DataGrid
                    disableColumnMenu
                    checkboxSelection={checkCreatePermission("MockSection")}
                    selectionModel={selectedSections}
                    onSelectionModelChange={handleSelectedRowChange}
                    pagination
                    autoPageSize={false}
                    paginationMode='server'
                    filterMode='server'
                    density='compact'
                    loading={loading}
                    rowCount={total}
                    onPageChange={setPageNo}
                    onPageSizeChange={setPageSize}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    rows={items}
                    getRowId={(row) => row.templateId}
                    columns={columns}
                    onFilterModelChange={handleFilterChange}
                    components={{
                        Toolbar: GridToolbar,
                        LoadingOverlay: LinearProgress,
                        Pagination: ListPagination,
                    }}
                    componentsProps={{
                        pagination: {
                            rowCount: total
                        }
                    }}
                />
            </div>
            <ConfirmationDialog
                message={
                    `This template will permanantly get deleted, Are you sure?`
                }
                open={!!activeItemId}
                title="Delete Mock Template"
                primaryActionVariant="error"
                primaryAction="Yes"
                secondaryAction="Cancel"
                onClose={() => setActiveItemId(null)}
                onSuccess={handleConfirmationSuccess}
            />
            <EntityViewDialog
                edit
                open={selectedSection}
                entityId={id}
                getEntity={getMockSectionById}
                onClose={handleCloseEdit}
                title={"Edit Mock Section"}
            />
            <DuplicateMockTemplateDialog
                open={openDuplicateDialog}
                onClose={() => setOpenDuplicateDialog(false)}
                fromTemplate={selectedTemplates[0]?.templateId}
                toTemplateName={`${selectedTemplates[0]?.templateName} copy`}
            />
        </Box>
    );
}